import { useState } from "react";
import cities from "../data/cities-min";
import useOnclickOutside from "react-cool-onclickoutside";

export default function Autocomplete({ setLocalisation }) {
  const [value, setValue] = useState("");
  const [filteredCities, setFilteredCities] = useState(cities);
  const ref = useOnclickOutside(() => {
    setValue("");
  });

  const onChangeValue = (e) => {
    setValue(e.target.value);
    const ci = cities
      .filter((city) => city.name.toLowerCase().startsWith(e.target.value.toLowerCase()))
      .slice(0, 5);
    setFilteredCities(ci);
  };

  return (
    <div ref={ref} className="w-full sm:w-96 text-gray-900 relative mb-2">
      <input
        className="w-full px-4 py-2 rounded-lg focus:outline-none first-letter:uppercase"
        type="text"
        placeholder="Search for a city..."
        onChange={onChangeValue}
        value={value}
      />
      {value.length > 0 && filteredCities.length > 0 && (
        <ul className="w-full mt-2 bg-white py-2 rounded-lg font-medium absolute z-20">
          {value.length >= 3 ? (
            filteredCities.map((city) => (
              <li
                key={`${city.lon}-${city.lat}`}
                onClick={() => {
                  setLocalisation({
                    lon: city.lon,
                    lat: city.lat,
                    city: city.name,
                    countryCode: city.country_code,
                  });
                  setValue("");
                }}
                className="cursor-pointer hover:bg-gray-200 px-4 py-1"
              >
                {city.name}, {city.country_code}
              </li>
            ))
          ) : (
            <div className="px-4">Please enter at least 3 characters to search...</div>
          )}
        </ul>
      )}
    </div>
  );
}
