import { BsThreeDotsVertical } from "react-icons/bs";

export default function DailyForecastCard({ day, onClick }) {
  return (
    <div
      onClick={onClick}
      className="grid grid-cols-6 sm:grid-cols-7 lg:grid-cols-6 items-center px-2 pt-2 pb-3 bg-white/5 hover:bg-white/10 cursor-pointer transition ease-out rounded"
    >
      <span className="col-span-2 sm:col-span-2 lg:col-span-1">{day.time}</span>
      <div className="col-span-2 sm:col-span-2 lg:col-span-2 flex flex-col sm:flex-row items-center justify-center">
        <img className="w-10" src={`https://openweathermap.org/img/wn/${day.icon}@2x.png`} alt="" />
        <span>{`${day.temp.max} / ${day.temp.min}`}</span>
      </div>
      <span className="sm:col-span-2 lg:col-span-2 text-right break-all first-letter:uppercase hidden sm:inline">
        {day.description}
      </span>
      <div className="col-span-2 sm:col-span-1 lg:grid-cols-1 flex justify-end">
        <BsThreeDotsVertical fontSize={18} />
      </div>
    </div>
  );
}
