import classNames from "classnames";
import { useEffect, useState } from "react";
// import { toast, ToastContainer } from "react-toastify";
import {
  Autocomplete,
  DailyForecastCard,
  DailyForecastPopup,
  HourlyForecastChart,
} from "./components";
import fetchWeather from "./services/weather";
// import "react-toastify/dist/ReactToastify.css";
import toast, { Toaster } from "react-hot-toast";

function App() {
  const [weather, setWeather] = useState();
  const [units, setUnits] = useState("metric");
  const [localisation, setLocalisation] = useState({
    lon: "-7.6192",
    lat: "33.5928",
    city: "Casablanca",
    countryCode: "MA",
  });
  const [currentDay, setCurrentDay] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    toast.promise(fetchWeather({ ...localisation, units }), {
      loading: "Loading",
      success: (data) => {
        setWeather(data);
        return `${localisation.city} weather in ${units} units`;
      },
      error: "Error while loading weather",
    });
  }, [localisation, units]);

  if (!weather)
    return (
      <>
        <div className="w-full h-screen flex justify-center items-center">
          <span className="loader"></span>
        </div>
        <Toaster position="top-right" toastOptions={{ duration: 3000 }} />
      </>
    );

  return (
    <div className="flex flex-col items-center text-white pt-4 px-4 sm:px-10">
      <Autocomplete setLocalisation={setLocalisation} />
      <div className="w-full sm:w-[620px] flex flex-col items-center">
        <h2 className="text-3xl">{`${localisation.city}, ${localisation.countryCode}`}</h2>
        <div className="flex gap-x-4 mt-2 min-w-[180px] justify-center relative">
          <span className="text-8xl font-extralight">{weather.current.temp}</span>
          <div className="flex flex-col mt-2 gap-y-2 absolute -right-5">
            <button
              className={classNames(
                "w-6 h-6 font-semibold text-white rounded-full transition hover:scale-110",
                {
                  "bg-white !text-blue-700": units === "metric",
                }
              )}
              onClick={() => setUnits("metric")}
            >
              C
            </button>
            <button
              className={classNames(
                "w-6 h-6 font-semibold text-white rounded-full transition hover:scale-110",
                {
                  "bg-white !text-blue-700": units === "imperial",
                }
              )}
              onClick={() => setUnits("imperial")}
            >
              F
            </button>
          </div>
        </div>
        <p className="text-xl mt-2">{weather.current.weather}</p>
        <p className="text-lg mt-1">Updated as of {weather.current.dt}</p>
        <ul className="mt-1 flex gap-x-5">
          <li>Feels Like {weather.current.feels_like}°</li>
          <li>Humidity {weather.current.humidity}%</li>
          <li>Wind {weather.current.wind_speed}</li>
        </ul>
      </div>
      <div className="flex w-full lg:w-full xl:w-11/12 mt-6 gap-x-8 flex-col lg:flex-row">
        <div className="w-full lg:w-3/5">
          <h2 className="text-3xl">Hourly forecast</h2>
          <hr className="mt-2" />
          <div className="py-2">
            <HourlyForecastChart weather={weather} />
          </div>
        </div>
        <div className="w-full lg:w-2/5">
          <h2 className="text-3xl">5-day forecast</h2>
          <hr className="mt-2" />
          <div className="flex flex-col gap-y-1 py-2 relative">
            {weather.daily.map((day, key) => (
              <DailyForecastCard
                key={key}
                onClick={() => {
                  setCurrentDay(key);
                  setShowPopup(true);
                }}
                day={day}
              />
            ))}
            <DailyForecastPopup
              day={weather.daily[currentDay]}
              show={showPopup}
              setShow={setShowPopup}
              units={units}
            />
          </div>
        </div>
      </div>
      <Toaster position="top-right" />
    </div>
  );
}

export default App;
