import { BsWind, BsCloudDrizzle, BsDroplet, BsX } from "react-icons/bs";
import night from "../assets/img/night.jpg";
import sunset from "../assets/img/sunset.jpg";
import afternoon from "../assets/img/afternoon.jpg";
import sunrise from "../assets/img/sunrise.jpg";
import classNames from "classnames";
import useOnclickOutside from "react-cool-onclickoutside";

export default function DailyForecastPopup({ day, show, setShow, units }) {
  const ref = useOnclickOutside(() => {
    setShow(false);
  });
  return (
    <div
      ref={ref}
      className={classNames(
        "top-2 left-0 text-gray-900 bg-white absolute w-full h-full px-8 pt-4 pb-5 transition ease-out rounded-md shadow-xl",
        { "opacity-100 scale-100": show, "opacity-0 scale-95 pointer-events-none": !show }
      )}
    >
      <button
        className="w-8 h-8 absolute right-2 top-2 transition hover:scale-125"
        onClick={() => setShow(false)}
        aria-label="close popup"
      >
        <BsX fontSize={30} />
      </button>
      <div>
        <p className="font-bold first-letter:uppercase">{day.description}.</p>
        <p>
          The high will be {day.temp.max}, the low will be {day.temp.min}.
        </p>
      </div>
      <div className="flex gap-x-4 mt-3">
        <div className="flex items-center gap-x-2">
          <BsCloudDrizzle /> <span>{day.rain}mm</span>
        </div>
        <div className="flex items-center gap-x-2">
          <BsWind /> <span>{day.wind_speed}</span>
        </div>
        <div className="flex items-center gap-x-2">
          <BsDroplet /> <span>{day.humidity}%</span>
        </div>
      </div>
      <div className="mt-3">
        <div>Temperature</div>
        <div className="flex gap-x-2 mt-1">
          <div className="flex flex-col items-center gap-y-1">
            <img className="rounded w-14" src={sunrise} alt="" />
            <span>{day.temp.morning}</span>
          </div>
          <div className="flex flex-col items-center gap-y-1">
            <img className="rounded w-14" src={afternoon} alt="" />
            <span>{day.temp.afternoon}</span>
          </div>
          <div className="flex flex-col items-center gap-y-1">
            <img className="rounded w-14" src={sunset} alt="" />
            <span>{day.temp.evening}</span>
          </div>
          <div className="flex flex-col items-center gap-y-1">
            <img className="rounded w-14" src={night} alt="" />
            <span>{day.temp.night}</span>
          </div>
        </div>
      </div>
      <div className="flex gap-x-4 mt-3">
        <div className="flex flex-col items-center">
          <span className="uppercase text-sm font-semibold">sunrise</span>
          <span>{day.sunrise}</span>
        </div>
        <div className="flex flex-col items-center">
          <span className="uppercase text-sm font-semibold">sunset</span>
          <span>{day.sunset}</span>
        </div>
      </div>
    </div>
  );
}
