import { DateTime } from "luxon";

const createUrl = (lon, lat, units) => {
  const url = new URL("https://api.openweathermap.org/data/2.5/onecall");
  url.searchParams.append("lon", lon);
  url.searchParams.append("lat", lat);
  url.searchParams.append("exclude", "minutely,alerts");
  url.searchParams.append("units", units);
  url.searchParams.append("appid", process.env.REACT_APP_WEATHER_API_KEY);
  return url;
};

const formatToLocalTime = (secs, zone, format) => {
  return DateTime.fromSeconds(secs).setZone(zone).toFormat(format);
};

const formatWeather = (weather, units) => {
  let formattedWeather = {
    lat: weather.lat,
    lon: weather.lon,
    timezone: weather.timezone,
    current: {
      dt: formatToLocalTime(weather.current.dt, weather.timezone, "HH:mm"),
      sunrise: formatToLocalTime(weather.current.sunrise, weather.timezone, "HH:mm"),
      sunset: formatToLocalTime(weather.current.sunset, weather.timezone, "HH:mm"),
      weather: weather.current.weather[0].main,
      feels_like: weather.current.feels_like.toFixed(),
      humidity: weather.current.humidity,
      wind_speed: `${weather.current.wind_speed.toFixed()}${units === "metric" ? "m/s" : "mph"}`,
      temp: `${weather.current.temp.toFixed()}°`,
      night: weather.current.weather[0].icon.endsWith("n"),
    },
    daily: weather.daily
      .map((day) => ({
        time: formatToLocalTime(day.dt, weather.timezone, "ccc dd"),
        temp: {
          min: day.temp.min.toFixed() + (units === "metric" ? "°C" : "°F"),
          max: day.temp.max.toFixed() + (units === "metric" ? "°C" : "°F"),
          morning: day.temp.morn.toFixed() + (units === "metric" ? "°C" : "°F"),
          afternoon: day.temp.day.toFixed() + (units === "metric" ? "°C" : "°F"),
          evening: day.temp.eve.toFixed() + (units === "metric" ? "°C" : "°F"),
          night: day.temp.night.toFixed() + (units === "metric" ? "°C" : "°F"),
        },
        sunrise: formatToLocalTime(day.sunrise, weather.timezone, "HH:mm"),
        sunset: formatToLocalTime(day.sunset, weather.timezone, "HH:mm"),
        description: day.weather[0].description,
        icon: day.weather[0].icon,
        humidity: day.humidity.toFixed(),
        wind_speed: `${day.wind_speed.toFixed()}${units === "metric" ? "m/s" : "mph"}`,
        rain: day.rain ? day.rain.toFixed() : 0,
      }))
      .slice(0, 5),
    hourly: weather.hourly
      .map((hour) => ({
        time: formatToLocalTime(hour.dt, weather.timezone, "HH:mm"),
        temp: hour.temp.toFixed(),
      }))
      .slice(0, 24)
      .filter((_hour, i) => i % 2 === 0),
  };
  return formattedWeather;
};

export default async function fetchWeather(settings) {
  try {
    const { lon, lat, units } = settings;
    const url = createUrl(lon, lat, units);
    const res = await fetch(url);
    const temp = await res.json();
    const weather = formatWeather(temp, units);
    console.log(weather);
    return weather;
  } catch (err) {
    throw Error("Error");
  }
}
