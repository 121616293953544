import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  tension: 0.3,
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        borderColor: "rgb(255,255,255)",
        borderWidth: 1.5,
      },
      ticks: {
        color: "rgb(255,255,255)",
        font: {
          weight: "500",
          size: 14,
        },
      },
    },
    y: {
      grid: {
        display: false,
        borderColor: "rgb(255,255,255)",
        borderWidth: 1.5,
      },
      ticks: {
        color: "rgb(255,255,255)",
        font: {
          weight: "500",
          size: 14,
        },
        // stepSize: 5,
      },
    },
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

export default function HourlyForecastChart({ weather }) {
  // const stepSize = (
  //   (Math.max(...weather.hourly.map((hour) => hour.temp)) -
  //     Math.min(...weather.hourly.map((hour) => hour.temp))) /
  //   5
  // ).toFixed();

  return (
    <Line
      className="backdrop-blur-xl bg-white/5 p-2 rounded"
      height={300}
      options={{
        ...options,
        scales: {
          ...options.scales,
          y: {
            ...options.scales.y,
            ticks: {
              ...options.scales.y.ticks,
              maxTicksLimit: 10,
            },
          },
        },
      }}
      data={{
        labels: weather.hourly.map((hour) => hour.time),
        datasets: [
          {
            data: weather.hourly.map((hour) => hour.temp),
            borderColor: "rgb(255,255,255)",
            borderWidth: 2,
            fill: true,
            backgroundColor: "rgb(255,255,255,.1)",
          },
        ],
      }}
    />
  );
}
